/**/
/*Salvattore settings*/
/**/
@media screen and (max-width: 360px){
    #posts[data-columns]::before, #similar-posts[data-columns]::before {
        content: '1 .column.size-1of1';
    }
}

@media screen and (min-width: 361px) and (max-width: 680px) {
    #posts[data-columns]::before, #similar-posts[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}
@media screen and (min-width: 681px) and (max-width: 767px) {
    #posts[data-columns]::before, #similar-posts[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    #posts[data-columns]::before, #similar-posts[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}
@media screen and (min-width: 992px) {
    #posts[data-columns]::before, #similar-posts[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}
.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }
/**/
/* End Salvattore settings*/
/**/


@media screen and (max-width: 360px){
    .navbar-form .form-control {
        width: 170px !important;
    }
    .navbar-nav>li>a {
        padding: 17px 10px !important;
    }
}
@media screen and (min-width: 361px) and (max-width: 680px) {
    .navbar-form .form-control {
        width: 210px !important;
    }
    .video-container {
        padding-bottom: 52% !important;
    }
}
@media screen and (min-width: 681px) and (max-width: 767px) {
    .navbar-form .form-control {
        width: 320px !important;
    }
    .video-container {
        padding-bottom: 52.7% !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-form .form-control {
        width: 270px !important;
    }
    .video-container {
        padding-bottom: 52.9% !important;
    }
}
@media screen and (min-width: 992px) {
    .navbar-form .form-control {
        width: 420px !important;
    }
    .video-container {
        padding-bottom: 53.7% !important;
    }
}
@media screen and (max-width: 767px) {
    .header {
        margin-left: 0;
        padding: 0;
    }
    .navbar-header {
        padding: 10px;
        text-align: center;
        width: 48px;
        margin: 0 !important;
    }
    .navbar-brand {
        width: 48px;
        float:none;
        padding: 0;
    }
    .navbar-form .btn-search {
        top: 12px !important;
    }
    .page-header-fixed {
        padding-top: 54px;
    }
    .pace-progress {
        top: 55px;
    }
    .pace .pace-activity {
        top: 65px;
    }
}
.post-title {
    font-size: 13px;
    color: #337ab7;
    margin: 5px 0px 0px;
    font-weight: 600;
}

.single-post-title {
    font-size: 18px;
    font-weight: 700;
}

.navbar-form {
    position: relative !important;
}

.navbar-form .btn-search {
    top: 0;
}

.navbar-brand {
    width: auto !important;
}
.navbar-header img {
    display: inline-block;
    margin-right: 10px;
    width: 34px;
    height: 34px;
}

.login-button {
    color: #fff !important;
    padding: 5px 10px !important;
    margin: 10px 15px;
}

.section-title {
    font-size: 11px;
    margin: 0 0 5px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    color: #333;
    text-align: center;
}

.section-title:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    /*top: 0;*/
    background: #222;
    top: 50%;
    margin-top: -1px;
    z-index: 0;
}

.section-title span {
    position: relative;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
}

.video-container {
    position: relative;
    padding-bottom: 54%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    /*position: relative;*/
    /*padding-bottom: 56.25%;*/
    /*padding-top: 30px; height: 0; overflow: hidden;*/
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.well-content {
    border: 1px solid #bdbdbd;
    padding: 10px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    position: relative;
}

.cell-content {
    padding: 10px;
    position: relative;
}

.cell-content img {
    margin-bottom: 8px;
}

.cell-content .post-title {
    font-size: 15px;
    font-weight: 600;
}

.cell-content a:hover {
    text-decoration: none;
}

.del-post-btn {
    color: red;
    position: absolute;
    right: 4px;
    top: 0px
}

textarea.autoExpand {
    resize: none;
}